export default {
    TERMS: [
        [18, '5% 10 Net 30'],
        [19, '5% 20 Net 30'],
        [7, '.5% Net 30'],
        [20, '.75% 10 Net 30'],
        [31, '1% 1st 10th Net 25'],
        [23, '1% 5 Net 10'],
        [21, '1% 10 Net 20'],
        [22, '1% 10 Net 30'],
        [16, '1% 20 Net 30'],
        [8, '1% Net 30'],
        [9, '1.5% Net 30'],
        [1, '2% 10 Net 30'],
        [12, '2% 15 Net 30'],
        [11, '2% 20 Net 30'],
        [5, '2% 10 Net 45'],
        [24, '2% Net 60'],
        [17, '4.5% 10 Net 75'],
        [14, '45NPR'],
        [13, '60NPR'],
        [15, '75NPR'],
        [6, 'COD'],
        [25, 'Net 10'],
        [26, 'Net 15'],
        [27, 'Net 20'],
        [28, 'Net 25'],
        [2, 'Net 30'],
        [29, 'Net 40'],
        [3, 'Net 45'],
        [4, 'Net 60'],
        [10, 'Net 70'],
        [30, 'Net 90'],
    ]
}